<template>
  <main class="page">
    <section class="section section__article">
      <div class="article__wrapper" v-if="article">
        <h1
          v-if="article.name"
          class="article__title title title-lg text-center mb-4"
        >
          {{ parse(article.name) }}
        </h1>
        <div class="article__content article-content">
          <div
            v-for="chapter in article.chapters"
            :key="chapter.name"
            class="article-content__chapter chapter"
          >
            <h2
              :id="chapter.hash?.id || undefined"
              :class="{
                'article-content__lighted': chapter.hash?.id === routeHash,
              }"
              v-if="chapter.name"
              class="chapter__title title title-md my-4"
            >
              {{ parse(chapter.name) }}
            </h2>
            <template
              v-for="(paragraph, index) in chapter.paragraphs"
              :key="index"
            >
              <p
                class="mb-3 text text-md"
                v-if="paragraph.type === 'text'"
                :id="paragraph.hash?.id || undefined"
                :class="{
                  'article-content__lighted': paragraph.hash?.id === routeHash,
                }"
                v-html="parse(paragraph.content)"
              ></p>
              <ul v-else-if="paragraph.type === 'unorderedList'" class="mb-3">
                <li
                  v-for="(li, index) in paragraph.content"
                  :key="index"
                  :id="
                    (paragraph.hash?.contentNumber === index + 1 &&
                      paragraph.hash?.id) ||
                    undefined
                  "
                  :class="{
                    'article-content__lighted':
                      paragraph.hash?.contentNumber === index + 1 &&
                      paragraph.hash?.id === routeHash,
                  }"
                  class="mb-2 text text-md"
                  v-html="parse(li)"
                ></li>
              </ul>
              <ol v-else-if="paragraph.type === 'numberedList'" class="mb-3">
                <li
                  v-for="(li, index) in paragraph.content"
                  :key="index"
                  class="mb-2 text text-md"
                  :id="
                    (paragraph.hash?.contentNumber === index + 1 &&
                      paragraph.hash?.id) ||
                    undefined
                  "
                  :class="{
                    'article-content__lighted':
                      paragraph.hash?.contentNumber === index + 1 &&
                      paragraph.hash?.id === routeHash,
                  }"
                  v-html="parse(li)"
                ></li>
              </ol>
              <ol
                v-else-if="paragraph.type === 'abcList'"
                type="a"
                :id="
                  (paragraph.hash?.contentNumber === index + 1 &&
                    paragraph.hash?.id) ||
                  undefined
                "
                :class="{
                  'article-content__lighted':
                    paragraph.hash?.contentNumber === index + 1 &&
                    paragraph.hash?.id === routeHash,
                }"
                class="mb-3 text text-md"
              >
                <li
                  v-for="(li, index) in paragraph.content"
                  :key="index"
                  class="mb-2"
                  v-html="parse(li)"
                ></li>
              </ol>
              <div
                v-else-if="paragraph.type === 'table'"
                class="table-wrapper mb-5 mt-4"
              >
                <table
                  :id="
                    (paragraph.hash?.contentNumber === index + 1 &&
                      paragraph.hash?.id) ||
                    undefined
                  "
                  :class="{
                    'article-content__lighted':
                      paragraph.hash?.contentNumber === index + 1 &&
                      paragraph.hash?.id === routeHash,
                  }"
                  class="text text-md"
                >
                  <tr>
                    <th
                      v-for="(th, index) in paragraph.content.head"
                      :key="index"
                      class="p-1"
                      v-html="parse(th)"
                    ></th>
                  </tr>
                  <tr
                    v-for="(tr, index) in paragraph.content.data"
                    :key="index"
                  >
                    <td
                      v-for="(td, index) in tr"
                      :key="index"
                      class="p-1"
                      v-html="parse(td)"
                    ></td>
                  </tr>
                </table>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Terms',
  data() {
    this.article = null
  },
  computed: {
    routeName: {
      get() {
        return this.$route.name
      },
    },
    routeHash: {
      get() {
        return this.$route.hash?.slice(1)
      },
    },
  },
  watch: {
    routeName(value) {
      this.getContent(value)
    },
  },
  created() {
    this.getContent(this.$route.name)
  },
  methods: {
    getContent(routeName) {
      const fileNamesMap = {
        [this.$ROUTER.NAME.TERMS]: 'terms',
        [this.$ROUTER.NAME.PRIVACY_POLICY]: 'privacyPolicy',
        [this.$ROUTER.NAME.DATA_RETENTION_POLICY]: 'dataRetentionPolicy',
      }
      const jsonName = fileNamesMap[routeName]
      if (!jsonName) {
        return
      }
      this.article = this.getJsonFile(jsonName)
      if (this.routeHash) {
        this.$nextTick(() => {
          this.scrollToElement(this.routeHash)
        })
      }
    },
    getJsonFile(name) {
      return require('./' + name + '.json')
    },
    scrollToElement(id) {
      const el = document.getElementById(id)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },
    parse(str) {
      let res = str
      if (typeof this.article.variables === 'object') {
        Object.entries(this.article.variables).forEach(([variable, value]) => {
          res = res.replaceAll(
            `{${variable}}`,
            process.env[`VUE_APP__${value}`] || value
          )
        })
      }
      return res
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
